<template>
  <v-container fluid>
    <v-row class="hidden-sm-and-down">
      <v-list color="#F2F2F2" style="border-radius:10px;">
        <v-list-item-group>
          <h5>
            Account
          </h5>
          <v-list-item
            v-for="(item, i) in menuitems"
            :key="i"
            :to="item.path"
            active-class="highlighted"
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>

    <v-row class="hidden-md-and-up">
      <v-slide-group center-active show-arrows>
        <v-slide-item
          v-for="(item, i) in menuitems"
          :key="i"
          active-class="highlighted"
          center-active
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-btn class="mx-2" depressed rounded :to="item.path">
            {{ item.text }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row class="mt-10 scanBar">
      <v-col cols="12">
        <p class="barcodeTitle"><b>qikPharma Mobile App</b></p>
        <p class="barcodeText">Search Anywhere, Anytime</p>
        <v-img
          alt="qikPharma App"
          class="centerImg"
          contain
          src="https://res.cloudinary.com/dxw06ktju/image/upload/v1716254239/barcode_ugvl6o.png"
          transition="scale-transition"
        />
        <p class="barcodeText">Scan or click to download</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RetailerMenu",

  components: {},

  data: () => ({
    menuitems: [
      { text: "Overview", path: "/overview/retailer_dashboard/home" },
      { text: "Point of Sale", path: "/retailer/0/pos" },
      { text: "Products", path: "/retailer/0/products" },
      { text: "My Orders", path: "/retailer_account/orders" },
      { text: "Shop Orders", path: "/retailer/my-shop-orders" },
      { text: "Feedback", path: "/retailer_account/feedbacks" },
      { text: "Shipping address", path: "/retailer/account/shipping_address" },
      { text: "Setting", path: "/retailer/account/settings" },
      { text: "Wallet", path: "/retailer/wallet" },
      { text: "Invite Friends", path: "/retailer/referals/en" },
      { text: "Help Center", path: "/en/help-center" }
    ],
  }),
};
</script>

<style scoped>
.scanBar {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.highlighted {
  background-color: whitesmoke !important;
  border-left: 3px solid #fa2d2d;
  /* border-left: 3px solid #7cbf46; */
}
</style>
